import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Login.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState(''); // 验证码状态
    const [csrfToken, setCsrfToken] = useState(''); // 存储 CSRF 令牌
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // 在组件加载时获取 CSRF 令牌
    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await axios.get('https://disneypdf.com/api/get-csrf-token', {
                    withCredentials: true, // 必须携带 Cookie
                });
                const token = response.data.csrfToken;
                setCsrfToken(token);
                axios.defaults.headers.common['x-csrf-token'] = token; // 设置 Axios 默认头部
            } catch (err) {
                console.error('无法获取 CSRF 令牌:', err);
                setError('无法获取 CSRF 令牌，请刷新页面重试');
            }
        };

        fetchCsrfToken();
    }, []);

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');

        // 校验字段是否为空
        if (!username || !password || !code) {
            setError('请填写所有字段');
            return;
        }

        setIsLoading(true);

        try {
            // 发送登录请求
            const response = await axios.post(
                'https://disneypdf.com/api/auth/login',
                {
                    username,
                    password,
                    code,
                },
                { withCredentials: true } // 确保发送 Cookie
            );

            // 登录成功
            localStorage.setItem('token', response.data.token);
            alert('登录成功！');
            window.location.href = '/';
        } catch (err) {
            setError(err.response?.data?.error || '登录失败');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <h2>登录到管理系统</h2>
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label>用户名</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="请输入用户名"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>密码</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="请输入密码"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>验证码</label>
                        <input
                            type="text"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            placeholder="请输入验证码"
                            required
                        />
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <button type="submit" className="login-button" disabled={isLoading || !csrfToken}>
                        {isLoading ? '登录中...' : '登录'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
