import React, { useState, useEffect } from "react";
import axios from "axios";
import "./VideoManagement.css";

const VideoManagement = () => {
  const [videos, setVideos] = useState([]);
  const [newVideo, setNewVideo] = useState({ src: "", title: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [csrfToken, setCsrfToken] = useState("");

  const API_URL = "https://disneypdf.com/api/videos";

  // 初始化：获取 CSRF 令牌和视频列表
  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      await fetchCsrfToken();
      await fetchVideos();
    } catch (err) {
      console.error('[Initialization Error]', err);
      setError("初始化失败，请刷新页面重试。");
    }
  };

  // 获取 CSRF 令牌
  const fetchCsrfToken = async () => {
    try {
      const response = await axios.get(`https://disneypdf.com/api/get-csrf-token`, {
        withCredentials: true, // 确保发送和接收 Cookie
      });
      const token = response.data.csrfToken;
      if (token) {
        setCsrfToken(token);
        axios.defaults.headers.common["x-csrf-token"] = token; // 设置 Axios 默认头部
        console.log("[CSRF Token Fetched]", token);
      } else {
        throw new Error("未接收到 CSRF 令牌");
      }
    } catch (err) {
      console.error("[Error Fetching CSRF Token]", err);
      setError("无法获取 CSRF 令牌，请刷新页面后重试。");
    }
  };

  // 获取视频列表
  const fetchVideos = async () => {
    setLoading(true);
    try {
      const response = await axios.get(API_URL, {
        withCredentials: true,
      });
      setVideos(response.data);
      setError("");
    } catch (err) {
      console.error("[Error Fetching Videos]", err);
      setError("无法加载视频列表，请稍后重试。");
    } finally {
      setLoading(false);
    }
  };

  // 添加视频
  const handleAddVideo = async () => {
    if (!newVideo.src || !newVideo.title) {
      setError("视频链接和标题不能为空。");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        API_URL,
        newVideo,
        { withCredentials: true } // 携带 Cookie 和 CSRF 令牌
      );
      setVideos((prev) => [...prev, response.data]);
      setNewVideo({ src: "", title: "" });
      setError("");
      setMessage("视频添加成功！");
    } catch (err) {
      console.error("[Error Adding Video]", err);
      setError("无法添加视频，请稍后重试。");
    } finally {
      setLoading(false);
    }
  };

  // 删除视频
  const handleDeleteVideo = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`${API_URL}/${id}`, {
        withCredentials: true,
      });
      setVideos((prev) => prev.filter((video) => video.id !== id));
      setError("");
      setMessage("视频删除成功！");
    } catch (err) {
      console.error("[Error Deleting Video]", err);
      setError("无法删除视频，请稍后重试。");
    } finally {
      setLoading(false);
    }
  };

  // 修改视频
  const handleUpdateVideo = async (id, updatedVideo) => {
    if (!updatedVideo.title || !updatedVideo.src) {
      setError("视频标题和链接不能为空。");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedVideo, {
        withCredentials: true,
      });
      setVideos((prev) =>
        prev.map((video) => (video.id === id ? response.data : video))
      );
      setError("");
      setMessage("视频更新成功！");
    } catch (err) {
      console.error("[Error Updating Video]", err);
      setError("无法更新视频，请稍后重试。");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="video-management">
      <h2>视频管理</h2>

      {/* 提示信息 */}
      {error && <p className="error">{error}</p>}
      {message && <p className="success-message">{message}</p>}

      {loading ? (
        <p>加载中...</p>
      ) : (
        <div>
          {/* 添加视频表单 */}
          <div className="add-video-form">
            <h3>添加新视频</h3>
            <input
              type="text"
              placeholder="视频链接"
              value={newVideo.src}
              onChange={(e) =>
                setNewVideo({ ...newVideo, src: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="视频标题"
              value={newVideo.title}
              onChange={(e) =>
                setNewVideo({ ...newVideo, title: e.target.value })
              }
            />
            <button onClick={handleAddVideo} disabled={loading || !csrfToken}>
              {loading ? "处理中..." : "添加视频"}
            </button>
          </div>

          {/* 视频列表 */}
          <ul className="video-list">
            {videos.map((video) => (
              <li key={video.id} className="video-item">
                <div>
                  <strong>标题：</strong> {video.title}
                </div>
                <div>
                  <strong>链接：</strong> {video.src}
                </div>
                <div>
                  <button
                    onClick={() =>
                      handleUpdateVideo(video.id, {
                        ...video,
                        title: prompt("新标题", video.title),
                        src: prompt("新链接", video.src),
                      })
                    }
                    disabled={loading || !csrfToken}
                  >
                    修改
                  </button>
                  <button
                    onClick={() => handleDeleteVideo(video.id)}
                    disabled={loading || !csrfToken}
                  >
                    删除
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default VideoManagement;
