import React, { useState, useEffect } from 'react';
import './ChangePassword.css';
import axios from 'axios';
import API_BASE_URL from '../config';

const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        initialize();
    }, []);

    // 初始化：获取 CSRF 令牌
    const initialize = async () => {
        try {
            await fetchCsrfToken();
        } catch (err) {
            console.error('[Initialization Error]', err);
            setError('初始化失败，请刷新页面后重试。');
        }
    };

    // 获取 CSRF 令牌
    const fetchCsrfToken = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/get-csrf-token`, { withCredentials: true });
            const token = response.data.csrfToken;
            if (token) {
                setCsrfToken(token);
                axios.defaults.headers.common['x-csrf-token'] = token; // 设置 Axios 默认头部
                console.log('[CSRF Token Fetched]', token);
            } else {
                throw new Error('未接收到 CSRF 令牌');
            }
        } catch (err) {
            console.error('[Error Fetching CSRF Token]', err);
            setError('无法获取 CSRF 令牌，请刷新页面后重试。');
        }
    };

    // 提交修改密码表单
    const handleChangePassword = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');

        // 检查新密码和确认密码是否匹配
        if (newPassword !== confirmPassword) {
            setError('新密码和确认密码不匹配');
            return;
        }

        setError('');
        setMessage('');
        try {
            const token = localStorage.getItem('token'); // 获取用户登录令牌
            if (!token) {
                setError('用户未登录，请重新登录后再尝试。');
                return;
            }
            const response = await axios.post(
                `${API_BASE_URL}/user/change-password`,
                { currentPassword, newPassword },
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // 用户登录令牌
                        'x-csrf-token': csrfToken, // CSRF 令牌
                    },
                    withCredentials: true, // 确保发送 Cookie
                }
            );
            setMessage(response.data.message || '密码修改成功');
            setError('');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (err) {
            console.error('[Error Changing Password]', err);
            setError(err.response?.data?.error || '修改密码失败，请稍后重试。');
        }
    };

    return (
        <div className="change-password-container">
            <h2>修改密码</h2>
            <form onSubmit={handleChangePassword}>
                <div className="form-group">
                    <label>当前密码</label>
                    <input
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        placeholder="请输入当前密码"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>新密码</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="请输入新密码"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>确认新密码</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="请再次输入新密码"
                        required
                    />
                </div>
                {error && <p className="error-message">{error}</p>}
                {message && <p className="success-message">{message}</p>}
                <button className="change-password-button" type="submit" disabled={!csrfToken}>
                    {csrfToken ? '修改密码' : '加载中...'}
                </button>
            </form>
        </div>
    );
};

export default ChangePassword;
