import React, { useState, useEffect } from "react";
import { message, Spin, Button, Input, List, Select } from "antd";
import { Icon } from "@iconify/react";
import facebookIcon from "@iconify/icons-simple-icons/facebook";
import twitterIcon from "@iconify/icons-simple-icons/twitter";
import instagramIcon from "@iconify/icons-simple-icons/instagram";
import youtubeIcon from "@iconify/icons-simple-icons/youtube";
import linkedinIcon from "@iconify/icons-simple-icons/linkedin";
import githubIcon from "@iconify/icons-simple-icons/github";
import lineIcon from "@iconify/icons-simple-icons/line";
import tiktokIcon from "@iconify/icons-simple-icons/tiktok";
import whatsappIcon from "@iconify/icons-simple-icons/whatsapp";
import redditIcon from "@iconify/icons-simple-icons/reddit";
import "./FooterManagement.css";

const { Option } = Select;

// 根据社交平台名称动态获取图标
const getIconByName = (name) => {
  const icons = {
    facebook: facebookIcon,
    twitter: twitterIcon,
    instagram: instagramIcon,
    youtube: youtubeIcon,
    linkedin: linkedinIcon,
    github: githubIcon,
    line: lineIcon,
    tiktok: tiktokIcon,
    whatsapp: whatsappIcon,
    reddit: redditIcon,
  };
  return icons[name?.toLowerCase()] || null;
};

const FooterManagement = ({ apiUrl }) => {
  const [footerData, setFooterData] = useState({ description: "", socialLinks: [] });
  const [newSocialLink, setNewSocialLink] = useState({ name: "", url: "" });
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");

  // 初始化：获取 CSRF 令牌和 Footer 数据
  useEffect(() => {
    if (!apiUrl) {
      message.error("API URL 未定义，请检查配置。");
      return;
    }
    initialize();
  }, [apiUrl]);

  const initialize = async () => {
    try {
      await fetchCsrfToken();
      await fetchFooterData();
    } catch (error) {
      console.error("[Initialization Error]:", error);
      message.error("初始化失败，请刷新页面后重试。");
    }
  };

  // 获取 CSRF 令牌
  const fetchCsrfToken = async () => {
    try {
      const response = await fetch(`${apiUrl}/get-csrf-token`, { credentials: "include" });
      if (!response.ok) throw new Error("Failed to fetch CSRF token");
      const data = await response.json();
      const token = data.csrfToken;
      if (token) {
        setCsrfToken(token);
        localStorage.setItem("csrfToken", token);
        localStorage.setItem("csrfTokenExpiry", Date.now() + 30 * 60 * 1000); // 设置 30 分钟过期
      } else {
        throw new Error("未接收到 CSRF 令牌");
      }
    } catch (err) {
      console.error("[Error Fetching CSRF Token]:", err);
      message.error("无法获取 CSRF 令牌，请刷新页面后重试。");
    }
  };

  // 检查 CSRF 令牌是否过期
  const isCsrfTokenExpired = () => {
    const expiry = localStorage.getItem("csrfTokenExpiry");
    return !expiry || Date.now() > parseInt(expiry, 10);
  };

  // 确保 CSRF 令牌有效
  const ensureCsrfToken = async () => {
    if (!csrfToken || isCsrfTokenExpired()) {
      await fetchCsrfToken();
    }
  };

  // 获取 Footer 数据
  const fetchFooterData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/footer`, { credentials: "include" });
      if (!response.ok) throw new Error(`Failed to fetch footer data: ${response.status}`);
      const data = await response.json();
      setFooterData(data);
    } catch (error) {
      console.error("[Error Fetching Footer Data]:", error);
      message.error("获取 Footer 数据失败");
    } finally {
      setLoading(false);
    }
  };

  // 添加社交链接
  const handleAddSocialLink = async () => {
    if (!newSocialLink.name || !newSocialLink.url) {
      message.warning("请确保所有社交链接字段都已填写");
      return;
    }

    try {
      await ensureCsrfToken();
      const response = await fetch(`${apiUrl}/footer/social-links`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-csrf-token": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify(newSocialLink),
      });
      if (!response.ok) throw new Error("Failed to add social link");
      message.success("添加社交链接成功");
      setNewSocialLink({ name: "", url: "" });
      fetchFooterData();
    } catch (error) {
      console.error("[Error Adding Social Link]:", error);
      message.error("添加社交链接失败");
    }
  };

  // 删除社交链接
  const handleDeleteSocialLink = async (id) => {
    if (!id) {
      message.error("删除失败，找不到有效的社交链接 ID");
      return;
    }

    try {
      await ensureCsrfToken();
      const response = await fetch(`${apiUrl}/footer/social-links/${id}`, {
        method: "DELETE",
        headers: {
          "x-csrf-token": csrfToken,
        },
        credentials: "include",
      });
      if (!response.ok) throw new Error("Failed to delete social link");
      message.success("删除社交链接成功");
      fetchFooterData();
    } catch (error) {
      console.error("[Error Deleting Social Link]:", error);
      message.error("删除社交链接失败");
    }
  };

  // 保存 Footer 描述
  const handleSaveFooterData = async () => {
    if (!footerData.description.trim()) {
      message.warning("描述不能为空");
      return;
    }

    setSaving(true);
    try {
      await ensureCsrfToken();
      const response = await fetch(`${apiUrl}/footer`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-csrf-token": csrfToken,
        },
        credentials: "include",
        body: JSON.stringify({ description: footerData.description }),
      });
      if (!response.ok) throw new Error("Failed to save footer data");
      const updatedFooter = await response.json();
      setFooterData(updatedFooter);
      message.success("页脚数据已保存");
    } catch (error) {
      console.error("[Error Saving Footer Data]:", error);
      message.error("保存页脚数据失败");
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="footer-management">
      <h3 className="sub-section-title">页脚管理</h3>
      {loading ? (
        <Spin tip="加载中..." />
      ) : (
        <>
          {/* 页脚描述编辑 */}
          <Input
            type="text"
            placeholder="描述"
            value={footerData.description}
            onChange={(e) => setFooterData({ ...footerData, description: e.target.value })}
            style={{ marginBottom: "10px" }}
          />
          <Button
            type="primary"
            onClick={handleSaveFooterData}
            loading={saving}
            style={{ marginBottom: "20px" }}
          >
            保存页脚数据
          </Button>

          {/* 社交链接管理 */}
          <h4 className="sub-section-title">社交链接</h4>
          <List
            dataSource={footerData.socialLinks}
            renderItem={(link) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    danger
                    onClick={() => handleDeleteSocialLink(link.id)}
                  >
                    删除
                  </Button>,
                ]}
              >
                <Icon icon={getIconByName(link.name)} style={{ fontSize: "1.5rem", marginRight: "8px" }} />
                {link.name} - {link.url}
              </List.Item>
            )}
          />
          <div style={{ marginTop: "20px" }}>
            {/* 新建社交链接 */}
            <Select
              placeholder="选择平台"
              value={newSocialLink.name}
              onChange={(value) => setNewSocialLink({ ...newSocialLink, name: value })}
              style={{ marginBottom: "10px", width: "100%" }}
            >
              {[
                "facebook",
                "twitter",
                "instagram",
                "youtube",
                "linkedin",
                "github",
                "line",
                "tiktok",
                "whatsapp",
                "reddit",
              ].map((platform) => (
                <Option value={platform} key={platform}>
                  {platform.charAt(0).toUpperCase() + platform.slice(1)}
                </Option>
              ))}
            </Select>
            <Input
              type="text"
              placeholder="社交链接 URL"
              value={newSocialLink.url}
              onChange={(e) => setNewSocialLink({ ...newSocialLink, url: e.target.value })}
              style={{ marginBottom: "10px" }}
            />
            <Button type="primary" onClick={handleAddSocialLink}>
              添加社交链接
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default FooterManagement;
