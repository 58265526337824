import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config';
import './MovieManagement.css';

function MovieManagement() {
  const [trendingMovies, setTrendingMovies] = useState([]);
  const [exclusiveMovies, setExclusiveMovies] = useState([]);
  const [newMovie, setNewMovie] = useState({
    title: '',
    category: 'trending',
    description: '',
    thumbnailUrl: '',
    detailImageUrl: '',
    releaseDate: '',
    ageRestricted: false,
    movieType: [],
  });
  const [movieTypes, setMovieTypes] = useState([]); // 泰语类型数组
  const [typeMap, setTypeMap] = useState({}); // 泰语到中文映射
  const [reverseTypeMap, setReverseTypeMap] = useState({}); // 中文到泰语映射
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [csrfToken, setCsrfToken] = useState(localStorage.getItem('csrfToken'));
  const [csrfTokenExpiry, setCsrfTokenExpiry] = useState(localStorage.getItem('csrfTokenExpiry'));
  const [editingMovie, setEditingMovie] = useState(null);

  useEffect(() => {
    if (!csrfToken || isTokenExpired()) {
      fetchCsrfToken();
    }
    fetchMovies();
    fetchMovieTypes();
  }, []);

  const isTokenExpired = () => {
    const expiry = localStorage.getItem('csrfTokenExpiry');
    return !expiry || new Date().getTime() > parseInt(expiry, 10);
  };

    const fetchCsrfToken = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get-csrf-token`, { withCredentials: true });
      const token = response.data.csrfToken;
      if (token) {
        setCsrfToken(token);
        axios.defaults.headers.common['x-csrf-token'] = token; // 设置全局 CSRF 令牌
      } else {
        throw new Error('Failed to fetch CSRF token');
      }
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };

  const fetchMovieTypes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/movie-types`, { withCredentials: true });
      const types = response.data; // 泰语类型数组
      setMovieTypes(types);

      // 创建映射关系
      const mapping = {
        "ตลก": "喜剧",
        "แอ็กชัน": "动作",
        "ไซไฟ": "科幻",
        "โรแมนติก": "爱情",
        "ระทึกขวัญ": "惊悚",
        "ผจญภัย": "冒险",
        "สารคดี": "纪录片",
        "แอนิเมชัน": "动画",
        "ประวัติศาสตร์": "历史",
        "ดนตรี": "音乐",
        "สงคราม": "战争",
        "แฟนตาซี": "奇幻",
        "สยองขวัญ": "恐怖",
        "อาชญากรรม": "犯罪",
        "ลึกลับ": "悬疑",
        "ชีวประวัติ": "传记",
        "ครอบครัว": "家庭",
        "กีฬา": "体育",
        "การเต้นรำ": "舞蹈",
        "ตะวันตก": "西部",
        "ดราม่า": "剧情",
        "วรรณกรรม": "文艺",
        "โทรทัศน์": "电视电影",
        "แฟนตาซีไซไฟ": "奇幻科幻",
        "สังคม": "社会",
        "ตำนาน": "传奇",
        "ชีวิต": "生活",
      };

      const typeMapData = types.reduce((map, thaiType) => {
        map[thaiType] = mapping[thaiType] || thaiType;
        return map;
      }, {});
      setTypeMap(typeMapData);

      const reverseMap = Object.fromEntries(Object.entries(typeMapData).map(([k, v]) => [v, k]));
      setReverseTypeMap(reverseMap);
    } catch (error) {
      console.error('获取电影类型失败:', error);
      alert('获取电影类型失败，请稍后再试');
    }
  };

  const fetchMovies = async () => {
    setLoading(true);
    try {
      const trendingResponse = await axios.get(`${API_BASE_URL}/movies/trending`, { withCredentials: true });
      const exclusiveResponse = await axios.get(`${API_BASE_URL}/movies/netflix-exclusive`, { withCredentials: true });
      setTrendingMovies(trendingResponse.data);
      setExclusiveMovies(exclusiveResponse.data);
    } catch (error) {
      console.error('获取电影数据失败:', error);
      alert('获取电影数据失败，请稍后再试');
    } finally {
      setLoading(false);
    }
  };

  const addMovie = async () => {
    const { title, category, thumbnailUrl, detailImageUrl, releaseDate, movieType } = newMovie;
    if (!title || !category || !thumbnailUrl || !detailImageUrl || !releaseDate || movieType.length === 0) {
      alert('请填写完整的电影信息');
      return;
    }

    setSaving(true);
    try {
      const token = csrfToken || localStorage.getItem('csrfToken');
      if (!token) {
        alert('CSRF token is missing, please refresh the page.');
        return;
      }
      const payload = {
        ...newMovie,
        movieType: newMovie.movieType.map((chineseType) => reverseTypeMap[chineseType]),
      };
      await axios.post(`${API_BASE_URL}/movies`, payload, {
        headers: {
          'x-csrf-token': token,
        },
        withCredentials: true,
      });
      alert('电影添加成功');
      fetchMovies();
      setNewMovie({
        title: '',
        category: 'trending',
        description: '',
        thumbnailUrl: '',
        detailImageUrl: '',
        releaseDate: '',
        ageRestricted: false,
        movieType: [],
      });
    } catch (error) {
      console.error('添加电影失败:', error);
      alert('添加电影失败，请稍后再试');
    } finally {
      setSaving(false);
    }
  };

  const deleteMovie = async (movieId) => {
    if (!movieId) {
      alert('无效的电影 ID');
      return;
    }

    try {
      const token = csrfToken || localStorage.getItem('csrfToken');
      if (!token) {
        alert('CSRF token is missing, please refresh the page.');
        return;
      }
      await axios.delete(`${API_BASE_URL}/movies/${movieId}`, {
        headers: {
          'x-csrf-token': token,
        },
        withCredentials: true,
      });
      alert('电影删除成功');
      fetchMovies();
    } catch (error) {
      console.error('删除电影失败:', error);
      alert('删除电影失败，请稍后再试');
    }
  };

  const startEditMovie = (movie) => {
  setEditingMovie({
    ...movie,
    category: movie.category || 'trending', // 初始化分类
    movieType: (movie.movieType || []).map((thaiType) => typeMap[thaiType] || thaiType), // 确保 movieType 存在
  });
};



  const updateMovie = async () => {
  if (!editingMovie || !editingMovie.id) {
    alert('无效的电影信息，无法更新');
    return;
  }

  const { id, category, ...movieData } = editingMovie;

  if (!category) {
    alert('请提供电影分类');
    return;
  }

  setSaving(true);
  try {
    const token = csrfToken || localStorage.getItem('csrfToken');
    if (!token) {
      alert('CSRF token is missing, please refresh the page.');
      return;
    }

    const payload = {
      ...movieData,
      category,
      movieType: movieData.movieType.map((chineseType) => reverseTypeMap[chineseType]), // 转换回泰语类型
    };

    await axios.put(`${API_BASE_URL}/movies/${id}`, payload, {
      headers: {
        'x-csrf-token': token,
      },
      withCredentials: true,
    });
    alert('电影更新成功');
    fetchMovies();
    setEditingMovie(null);
  } catch (error) {
    console.error('更新电影失败:', error);
    alert('更新电影失败，请稍后再试');
  } finally {
    setSaving(false);
  }
};


  const renderEditForm = () => (
  <div className="edit-movie-form">
    <h3>编辑电影</h3>
    <input
      type="text"
      placeholder="电影标题"
      value={editingMovie.title || ''}
      onChange={(e) => setEditingMovie({ ...editingMovie, title: e.target.value })}
      className="input-field"
    />
    <select
      value={editingMovie.category || 'trending'}
      onChange={(e) => setEditingMovie({ ...editingMovie, category: e.target.value })}
      className="input-field"
    >
      <option value="trending">现在流行</option>
      <option value="netflix-exclusive">仅限 Netflix 上播出</option>
    </select>
    <textarea
      placeholder="描述"
      value={editingMovie.description || ''}
      onChange={(e) => setEditingMovie({ ...editingMovie, description: e.target.value })}
      className="input-field"
    />
    <input
      type="text"
      placeholder="缩略图 URL"
      value={editingMovie.thumbnailUrl || ''}
      onChange={(e) => setEditingMovie({ ...editingMovie, thumbnailUrl: e.target.value })}
      className="input-field"
    />
    <input
      type="text"
      placeholder="详情图 URL"
      value={editingMovie.detailImageUrl || ''}
      onChange={(e) => setEditingMovie({ ...editingMovie, detailImageUrl: e.target.value })}
      className="input-field"
    />
    <input
      type="date"
      placeholder="上映时间"
      value={editingMovie.releaseDate || ''}
      onChange={(e) => setEditingMovie({ ...editingMovie, releaseDate: e.target.value })}
      className="input-field"
    />
    <label>
      <input
        type="checkbox"
        checked={editingMovie.ageRestricted || false}
        onChange={(e) => setEditingMovie({ ...editingMovie, ageRestricted: e.target.checked })}
      />
      是否限制为18+
    </label>
    <div className="movie-types">
      <p>选择电影类型：</p>
      {movieTypes.map((thaiType) => (
        <label key={thaiType}>
          <input
            type="checkbox"
            checked={editingMovie.movieType.includes(typeMap[thaiType])} // 显示中文
            onChange={() =>
              setEditingMovie((prev) => {
                const chineseType = typeMap[thaiType];
                return {
                  ...prev,
                  movieType: prev.movieType.includes(chineseType)
                    ? prev.movieType.filter((t) => t !== chineseType)
                    : [...prev.movieType, chineseType],
                };
              })
            }
          />
          {typeMap[thaiType]}
        </label>
      ))}
    </div>
    <button onClick={updateMovie} className="save-button">
      保存修改
    </button>
    <button onClick={() => setEditingMovie(null)} className="cancel-button">
      取消
    </button>
  </div>
);


  const renderMovieList = (movies, category) => (
    <ul className="movies-list">
      {movies.map((movie) => (
        <li key={movie.id} className="movie-item">
          <span className="movie-title">{movie.title}</span>
          {movie.thumbnailUrl && <img src={movie.thumbnailUrl} alt={movie.title} className="movie-thumbnail" />}
          <button onClick={() => startEditMovie(movie)} className="edit-button">
            编辑
          </button>
          <button onClick={() => deleteMovie(movie.id)} className="delete-button">
            删除
          </button>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="movie-management">
      <h2>电影管理</h2>

      {loading ? (
        <p>加载中...</p>
      ) : (
        <>
          {editingMovie ? (
            renderEditForm()
          ) : (
            <>
              <div className="add-movie-section">
                <h3>新增电影</h3>
                <input
                  type="text"
                  placeholder="电影标题"
                  value={newMovie.title}
                  onChange={(e) => setNewMovie({ ...newMovie, title: e.target.value })}
                  className="input-field"
                />
                <select
                  value={newMovie.category}
                  onChange={(e) => setNewMovie({ ...newMovie, category: e.target.value })}
                  className="input-field"
                >
                  <option value="trending">现在流行</option>
                  <option value="netflix-exclusive">仅限 Netflix 上播出</option>
                </select>
                <textarea
                  placeholder="描述"
                  value={newMovie.description}
                  onChange={(e) => setNewMovie({ ...newMovie, description: e.target.value })}
                  className="input-field"
                />
                <input
                  type="text"
                  placeholder="缩略图 URL"
                  value={newMovie.thumbnailUrl}
                  onChange={(e) => setNewMovie({ ...newMovie, thumbnailUrl: e.target.value })}
                  className="input-field"
                />
                <input
                  type="text"
                  placeholder="详情图 URL"
                  value={newMovie.detailImageUrl}
                  onChange={(e) => setNewMovie({ ...newMovie, detailImageUrl: e.target.value })}
                  className="input-field"
                />
                <input
                  type="date"
                  placeholder="上映时间"
                  value={newMovie.releaseDate}
                  onChange={(e) => setNewMovie({ ...newMovie, releaseDate: e.target.value })}
                  className="input-field"
                />
                <label>
                  <input
                    type="checkbox"
                    checked={newMovie.ageRestricted}
                    onChange={(e) => setNewMovie({ ...newMovie, ageRestricted: e.target.checked })}
                  />
                  是否限制为18+
                </label>
                <div className="movie-types">
                  <p>选择电影类型：</p>
                  {movieTypes.map((thaiType) => (
                    <label key={thaiType}>
                      <input
                        type="checkbox"
                        checked={newMovie.movieType.includes(typeMap[thaiType])}
                        onChange={() =>
                          setNewMovie((prev) => {
                            const chineseType = typeMap[thaiType];
                            return {
                              ...prev,
                              movieType: prev.movieType.includes(chineseType)
                                ? prev.movieType.filter((t) => t !== chineseType)
                                : [...prev.movieType, chineseType],
                            };
                          })
                        }
                      />
                      {typeMap[thaiType]}
                    </label>
                  ))}
                </div>
                <button onClick={addMovie} className="add-button" disabled={saving}>
                  {saving ? '保存中...' : '添加电影'}
                </button>
              </div>

              <div className="movie-category">
                <h3>现在流行的电影</h3>
                {renderMovieList(trendingMovies, 'trending')}
              </div>

              <div className="movie-category">
                <h3>仅限 Netflix 上播出的电影</h3>
                {renderMovieList(exclusiveMovies, 'netflix-exclusive')}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default MovieManagement;
	