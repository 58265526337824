import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar'; // 左侧导航栏组件
import HomePageManagement from './pages/HomePageManagement';
import MovieManagement from './pages/MovieManagement';
import FAQManagement from './pages/FAQManagement';
import FooterManagement from './pages/FooterManagement';
import VideoManagement from './pages/VideoManagement';
import BannerManagement from './pages/BannerManagement'; // 引入 Banner 管理组件
import API_BASE_URL from './config'; // 如果需要传递 API_BASE_URL
import Login from './pages/Login'; // 登录页面
import ChangePassword from './pages/ChangePassword'; // 引入修改密码组件
import ProtectedRoute from './components/ProtectedRoute'; // 路由保护组件
import UserProfile from './pages/UserProfile'; // 引入个人中心组件
import Welcome from './pages/Welcome'; // 引入 Welcome 组件
import ContactSubmissions from './pages/ContactSubmissions'; // 引入 ContactSubmissions 组件
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        {/* 登录页面 */}
        <Route path="/login" element={<Login />} />

        {/* 受保护的管理页面 */}
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <div className="app">
                {/* 左侧导航菜单 */}
                <Sidebar />
                {/* 主内容区 */}
                <div className="main-content">
                  <Routes>
                    {/* 欢迎页面 */}
                    <Route path="/" element={<Welcome />} />
                    {/* 个人中心页面 */}
                    <Route path="/profile" element={<UserProfile />} />
                    {/* 其他模块管理页面 */}
                    <Route path="/home" element={<HomePageManagement apiUrl={API_BASE_URL} />} />
                    <Route path="/banner" element={<BannerManagement apiUrl={API_BASE_URL} />} />
                    <Route path="/movies" element={<MovieManagement apiUrl={API_BASE_URL} />} />
                    <Route path="/faq" element={<FAQManagement apiUrl={API_BASE_URL} />} />
                    <Route path="/footer" element={<FooterManagement apiUrl={API_BASE_URL} />} />
                    <Route path="/video" element={<VideoManagement apiUrl={API_BASE_URL} />} />
                    {/* 修改密码页面 */}
                    <Route path="/change-password" element={<ChangePassword />} />
                    {/* 客户提交表单页面 */}
                    <Route path="/contact-submissions" element={<ContactSubmissions />} />
                  </Routes>
                </div>
              </div>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
