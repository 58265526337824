import React from 'react';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css'; // 引入样式

const UserProfile = () => {
  const navigate = useNavigate();

  // 模拟用户信息
  const userInfo = {
    username: 'admin',
    lastLogin: '2023-11-20 10:45:00',
  };

  return (
    <div className="user-profile">
      <h1>个人中心</h1>
      <div className="profile-details">
        <p><strong>用户名:</strong> {userInfo.username}</p>
        <p><strong>上次登录时间:</strong> {userInfo.lastLogin}</p>
      </div>
      <button className="change-password-button" onClick={() => navigate('/change-password')}>
        修改密码
      </button>
    </div>
  );
};

export default UserProfile;
