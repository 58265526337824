import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ContactSubmissions.css';

const ContactSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [csrfToken, setCsrfToken] = useState('');

  // 获取 CSRF 令牌
  const fetchCsrfToken = async () => {
    try {
      const response = await axios.get('https://disneypdf.com/api/get-csrf-token', {
        withCredentials: true,
      });
      setCsrfToken(response.data.csrfToken);
      axios.defaults.headers.common['X-CSRF-Token'] = response.data.csrfToken; // 全局设置 CSRF 令牌
    } catch (error) {
      console.error('获取 CSRF Token 出错:', error);
      setErrorMessage('无法获取 CSRF Token，请刷新页面后重试。');
    }
  };

  // 获取提交的数据
  const fetchSubmissions = async () => {
    try {
      const response = await axios.get('https://disneypdf.com/api/contact/submissions', {
        withCredentials: true,
      });
      setSubmissions(response.data);
    } catch (error) {
      console.error('获取表单数据错误:', error);
      setErrorMessage('无法获取表单数据，请稍后再试。');
    }
  };

  // 标记表单为已读
  const markAsRead = async (id) => {
    try {
      await axios.put(
        `https://disneypdf.com/api/contact/submissions/${id}/read`,
        {},
        {
          headers: {
            'X-CSRF-Token': csrfToken,
          },
          withCredentials: true,
        }
      );
      setSubmissions((prevSubmissions) =>
        prevSubmissions.map((submission) =>
          submission.id === id ? { ...submission, is_read: true } : submission
        )
      );
    } catch (error) {
      console.error('标记为已读出错:', error);
      setErrorMessage('标记表单为已读时出错，请稍后重试。');
    }
  };

  // 删除表单
  const deleteSubmission = async (id) => {
    try {
      await axios.delete(`https://disneypdf.com/api/contact/submissions/${id}`, {
        headers: {
          'X-CSRF-Token': csrfToken,
        },
        withCredentials: true,
      });
      setSubmissions((prevSubmissions) =>
        prevSubmissions.filter((submission) => submission.id !== id)
      );
    } catch (error) {
      console.error('删除表单出错:', error);
      setErrorMessage('删除表单时出错，请稍后重试。');
    }
  };

  // 定时获取数据并检测更新
  useEffect(() => {
    const fetchAllData = async () => {
      await fetchCsrfToken();
      await fetchSubmissions();
    };

    fetchAllData();

    const interval = setInterval(async () => {
      try {
        const response = await axios.get('https://disneypdf.com/api/contact/submissions', {
          withCredentials: true,
        });
        if (response.data.length > submissions.length) {
          alert('有新表单提交！');
          setSubmissions(response.data);
        }
      } catch (error) {
        console.error('获取表单数据错误:', error);
      }
    }, 10000);

    return () => clearInterval(interval); // 清理定时器
  }, [submissions.length]);

  return (
    <div className="contact-submissions-container">
      <h2>客户提交的表单</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {submissions.length > 0 ? (
        <table className="submissions-table">
          <thead>
            <tr>
              <th>名字</th>
              <th>姓氏</th>
              <th>电子邮件</th>
              <th>电话号码</th>
              <th>主题</th>
              <th>信息</th>
              <th>提交时间</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {submissions.map((submission) => (
              <tr
                key={submission.id}
                className={submission.is_read ? 'read' : 'unread'}
              >
                <td>{submission.first_name}</td>
                <td>{submission.last_name}</td>
                <td>{submission.email}</td>
                <td>{submission.phone}</td>
                <td>{submission.topic}</td>
                <td>{submission.message}</td>
                <td>{new Date(submission.created_at).toLocaleString()}</td>
                <td>
                  {!submission.is_read && (
                    <button
                      className="mark-as-read-button"
                      onClick={() => markAsRead(submission.id)}
                    >
                      标记已读
                    </button>
                  )}
                  <button
                    className="delete-button"
                    onClick={() => deleteSubmission(submission.id)}
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>暂无客户提交的数据。</p>
      )}
    </div>
  );
};

export default ContactSubmissions;
