import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config';
import './FAQManagement.css';

const FAQManagement = () => {
  const [faqs, setFaqs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [faqsPerPage] = useState(5); // 每页的 FAQ 数量
  const [newQuestion, setNewQuestion] = useState(''); // 新问题
  const [newAnswer, setNewAnswer] = useState(''); // 新答案
  const [message, setMessage] = useState(''); // 成功提示
  const [error, setError] = useState(''); // 错误提示
  const [loading, setLoading] = useState(false); // 请求状态
  const [csrfToken, setCsrfToken] = useState(''); // CSRF 令牌状态

  useEffect(() => {
    initialize();
  }, []);

  // 初始化：获取 CSRF 令牌和 FAQ 数据
  const initialize = async () => {
    try {
      await fetchCsrfToken();
      await fetchFAQs();
    } catch (err) {
      console.error('[Initialization Error]', err);
      setError('初始化失败，请检查网络或服务器设置。');
    }
  };

  // 获取 CSRF 令牌
  const fetchCsrfToken = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/get-csrf-token`, {
        withCredentials: true, // 确保发送和接收 Cookie
      });
      const token = response.data.csrfToken;
      setCsrfToken(token); // 保存令牌到状态
      axios.defaults.headers.common['x-csrf-token'] = token; // 设置 Axios 默认头部
      console.log('[CSRF Token Fetched]', token);
    } catch (err) {
      console.error('[Error Fetching CSRF Token]', err);
      setError('无法获取 CSRF 令牌，请刷新页面后重试。');
    }
  };

  // 获取 FAQ 列表
  const fetchFAQs = async () => {
    try {
      console.log('[Fetching FAQs]');
      const response = await axios.get(`${API_BASE_URL}/faqs`, {
        withCredentials: true, // 携带 Cookie
      });
      console.log('[FAQs Fetched]', response.data);
      setFaqs(response.data);
    } catch (err) {
      console.error('[Error Fetching FAQs]', err);
      setError('获取 FAQ 列表失败，请稍后重试。');
    }
  };

  // 添加 FAQ
  const addFAQ = async () => {
    if (!newQuestion.trim() || !newAnswer.trim()) {
      setError('请填写问题和答案。');
      return;
    }

    setLoading(true);
    try {
      console.log('[Adding FAQ] CSRF Token:', csrfToken);

      const response = await axios.post(
        `${API_BASE_URL}/faqs`,
        { question: newQuestion, answer: newAnswer },
        {
          withCredentials: true, // 确保发送 Cookie
        }
      );

      console.log('[FAQ Added]', response.data);
      setFaqs([...faqs, response.data]);
      setNewQuestion('');
      setNewAnswer('');
      setMessage('FAQ 添加成功。');
      setError('');
    } catch (err) {
      console.error('[Error Adding FAQ]', err);
      setError('添加 FAQ 失败，请稍后重试。');
    } finally {
      setLoading(false);
    }
  };

  // 删除 FAQ
  const deleteFAQ = async (id) => {
    setLoading(true);
    try {
      console.log('[Deleting FAQ] ID:', id);

      await axios.delete(`${API_BASE_URL}/faqs/${id}`, {
        withCredentials: true, // 携带 Cookie
      });

      console.log('[FAQ Deleted] ID:', id);
      setFaqs(faqs.filter((faq) => faq.id !== id));
      setMessage('FAQ 删除成功。');
      setError('');
    } catch (err) {
      console.error('[Error Deleting FAQ]', err);
      setError('删除 FAQ 失败，请稍后重试。');
    } finally {
      setLoading(false);
    }
  };

  // 分页逻辑
  const indexOfLastFAQ = currentPage * faqsPerPage;
  const indexOfFirstFAQ = indexOfLastFAQ - faqsPerPage;
  const currentFaqs = faqs.slice(indexOfFirstFAQ, indexOfLastFAQ);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(faqs.length / faqsPerPage);

  return (
    <div className="faq-container">
      <h2 className="faq-header">FAQ 管理</h2>

      {/* 提示信息 */}
      {message && <p className="success-message">{message}</p>}
      {error && <p className="error-message">{error}</p>}

      {/* 添加 FAQ 表单 */}
      <div className="new-faq">
        <h3>添加新 FAQ</h3>
        <input
          type="text"
          placeholder="输入问题"
          value={newQuestion}
          onChange={(e) => setNewQuestion(e.target.value)}
          disabled={loading}
        />
        <textarea
          placeholder="输入答案"
          value={newAnswer}
          onChange={(e) => setNewAnswer(e.target.value)}
          disabled={loading}
        />
        <button onClick={addFAQ} disabled={loading || !csrfToken}>
          {loading ? '处理中...' : '添加 FAQ'}
        </button>
      </div>

      {/* FAQ 列表 */}
      <h3>FAQ 列表</h3>
      <ul className="faq-list">
        {currentFaqs.map((faq) => (
          <li key={faq.id} className="faq-item">
            <div>{faq.question}</div>
            <div>{faq.answer}</div>
            <button onClick={() => deleteFAQ(faq.id)} disabled={loading}>
              删除
            </button>
          </li>
        ))}
      </ul>

      {/* 分页 */}
      <div className="pagination">
        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1 || loading}>
          上一页
        </button>
        <span>第 {currentPage} 页，共 {totalPages} 页</span>
        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages || loading}>
          下一页
        </button>
      </div>
    </div>
  );
};

export default FAQManagement;
