import React, { useState, useEffect } from 'react';
import desktopLogo from '../assets/desktop-logo.png'; // 必须放在文件顶部	
import './Welcome.css';

function Welcome({ username }) {
  const [stats, setStats] = useState({ movies: 0, faqs: 0, videos: 0 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        // 模拟 API 请求，获取统计数据
        const response = await fetch('/api/stats');
        const data = await response.json();
        setStats(data);
      } catch (error) {
        console.error('Failed to fetch stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="welcome-page">
      {loading ? (
        <div className="loading">加载中...</div>
      ) : (
        <>
          <div className="welcome-banner"><img src={desktopLogo} alt="欢迎" className="welcome-image" />
            <h1>欢迎回来，{username || "管理员"}！</h1>
            <p>您可以通过此控制面板管理网站的各个方面。选择左侧菜单开始管理。</p>
          </div>

          <div className="stats">
            <h2>当前统计数据</h2>
            <ul>
              <li>电影数量：{stats.movies}</li>
              <li>FAQ 数量：{stats.faqs}</li>
              <li>视频数量：{stats.videos}</li>
            </ul>
          </div>

          <div className="quick-links">
            <h2>快捷操作</h2>
            <div className="cards-container">
              <div className="card">
                <h3>电影管理</h3>
                <p>管理您的电影目录。</p>
                <button className="button" onClick={() => window.location.href = "/movies"}>开始管理</button>
              </div>
              <div className="card">
                <h3>FAQ 管理</h3>
                <p>管理常见问题。</p>
                <button className="button" onClick={() => window.location.href = "/faq"}>开始管理</button>
              </div>
              <div className="card">
                <h3>视频管理</h3>
                <p>管理平台中的所有视频。</p>
                <button className="button" onClick={() => window.location.href = "/video"}>开始管理</button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Welcome;
