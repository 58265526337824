import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './BannerManagement.css'; // 添加样式文件路径

const BannerManagement = ({ apiUrl }) => {
  const [bannerImage, setBannerImage] = useState('');
  const [bannerId, setBannerId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [csrfToken, setCsrfToken] = useState('');

  // 初始化：获取 CSRF 令牌和 Banner 数据
  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      await fetchCsrfToken();
      await fetchBannerData();
    } catch (err) {
      console.error('[Initialization Error]', err);
      setError('初始化失败，请刷新页面后重试。');
    }
  };

  // 获取 CSRF 令牌
  const fetchCsrfToken = async () => {
    try {
      const response = await axios.get(`${apiUrl}/get-csrf-token`, { withCredentials: true });
      const token = response.data.csrfToken;
      if (token) {
        setCsrfToken(token);
        axios.defaults.headers.common['x-csrf-token'] = token; // 设置 Axios 默认头部
        console.log('[CSRF Token Fetched]', token);
      } else {
        throw new Error('未接收到 CSRF 令牌');
      }
    } catch (err) {
      console.error('[Error Fetching CSRF Token]', err);
      setError('无法获取 CSRF 令牌，请刷新页面后重试。');
    }
  };

  // 获取 Banner 数据
  const fetchBannerData = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${apiUrl}/banners`, { withCredentials: true });
      if (response.data.length > 0) {
        const banner = response.data[0];
        setBannerImage(banner.imageUrl);
        setBannerId(banner.id || banner._id); // 支持后端返回的 `id` 或 `_id`
        console.log('[Banner Data Fetched]', banner);
      } else {
        console.warn('未找到 Banner 数据');
      }
    } catch (err) {
      console.error('[Error Fetching Banner Data]', err);
      setError('获取 Banner 数据失败，请稍后重试。');
    } finally {
      setLoading(false);
    }
  };

  // 更新 Banner 数据
  const updateBannerImage = async () => {
    if (!bannerId) {
      alert('没有要更新的 Banner');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const response = await axios.put(
        `${apiUrl}/banners/${bannerId}`,
        { imageUrl: bannerImage },
        {
          withCredentials: true,
        }
      );
      setBannerImage(response.data.imageUrl);
      alert('Banner 图片更新成功');
      setError('');
    } catch (err) {
      console.error('[Error Updating Banner Image]', err);
      setError('更新 Banner 数据失败，请稍后重试。');
    } finally {
      setLoading(false);
    }
  };

  // 删除 Banner 数据
  const deleteBanner = async () => {
    if (!bannerId) {
      alert('没有要删除的 Banner');
      return;
    }

    if (!window.confirm('确定要删除该 Banner 吗？')) return;

    setLoading(true);
    setError('');
    try {
      await axios.delete(`${apiUrl}/banners/${bannerId}`, {
        withCredentials: true,
      });
      alert('Banner 删除成功');
      setBannerImage('');
      setBannerId('');
      setError('');
    } catch (err) {
      console.error('[Error Deleting Banner]', err);
      setError('删除 Banner 数据失败，请稍后重试。');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="banner-management">
      <h3 className="sub-section-title">首页大图管理</h3>
      {loading && <p>加载中...</p>}
      {error && <p className="error">{error}</p>}
      <input
        type="text"
        className="input-field"
        placeholder="请输入 Banner 图片链接"
        value={bannerImage}
        onChange={(e) => setBannerImage(e.target.value)}
        disabled={loading}
      />
      <button
        className="btn update-btn"
        onClick={updateBannerImage}
        disabled={loading || !bannerImage}
      >
        {loading ? '更新中...' : '更新大图'}
      </button>
      <button
        className="btn delete-btn"
        onClick={deleteBanner}
        disabled={loading || !bannerId}
      >
        {loading ? '删除中...' : '删除大图'}
      </button>
      {bannerImage && (
        <img
          src={bannerImage}
          alt="Banner Preview"
          className="banner-preview"
        />
      )}
    </div>
  );
};

export default BannerManagement;
