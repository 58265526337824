import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token'); // 从 localStorage 检查 Token

    if (!token) {
        // 如果没有 Token，则重定向到 /login
        return <Navigate to="/login" replace />;
    }

    return children; // 如果已登录，渲染子组件
};

export default ProtectedRoute;
