import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePageManagement.css';

const HomePage = () => {
  const navigate = useNavigate();

  // 定义模块卡片数据
  const sections = [
    { title: 'Banner Management', description: 'Add or edit Banner.', path: '/banner', icon: '🖼️' },  // 横幅图标
    { title: 'Movie Management', description: 'Add or edit movies.', path: '/movies', icon: '🍿' },  // 电影图标
    { title: 'FAQ Management', description: 'Manage frequently asked questions.', path: '/faq', icon: '❓' },  // 问号图标
    { title: 'Footer Management', description: 'Customize footer content (social media links).', path: '/footer', icon: '🔗' },  // 社交媒体图标
    { title: 'Video Management', description: 'Manage homepage videos.', path: '/video', icon: '🎥' },  // 视频图标
    { title: 'Change Password', description: 'Update your account password.', path: '/change-password', icon: '🔑' },  // 修改密码图标
  ];

  return (
    <div className="home-page">
      <h1 className="home-title">Welcome to Home Page Management</h1>
      <p className="home-description">Click a section below to manage your homepage content.</p>

      <div className="grid-container">
        {sections.map((section, index) => (
          <div className="card" key={index} onClick={() => navigate(section.path)}>
            <div className="card-icon">{section.icon}</div>
            <h2 className="card-title">{section.title}</h2>
            <p className="card-description">{section.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
