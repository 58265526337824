import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // 全局样式
import App from './App'; // 主应用组件
import reportWebVitals from './reportWebVitals';

// 创建应用根节点并渲染
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// 性能报告，如果不需要可以注释掉
reportWebVitals();
