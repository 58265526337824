// Sidebar.js
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { FaHome, FaFilm, FaQuestionCircle, FaVideo, FaCog, FaKey, FaUser, FaSignOutAlt, FaEnvelope } from 'react-icons/fa'; // 引入图标

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // 清除 Token
    navigate('/login'); // 跳转到登录页面
  };

  return (
    <aside className="sidebar">
      <h2>菜单</h2>
      <nav>
        <ul>
          <li>
            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaHome className="icon" />
              首页
            </NavLink>
          </li>
          <li>
            <NavLink to="/profile" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaUser className="icon" />
              个人中心
            </NavLink>
          </li>
          <li>
            <NavLink to="/banner" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaCog className="icon" />
              横幅管理
            </NavLink>
          </li>
          <li>
            <NavLink to="/movies" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaFilm className="icon" />
              电影管理
            </NavLink>
          </li>
          <li>
            <NavLink to="/faq" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaQuestionCircle className="icon" />
              FAQ 管理
            </NavLink>
          </li>
          <li>
            <NavLink to="/footer" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaCog className="icon" />
              页脚管理
            </NavLink>
          </li>
          <li>
            <NavLink to="/video" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaVideo className="icon" />
              视频管理
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact-submissions" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaEnvelope className="icon" />
              客户提交
            </NavLink>
          </li>
          <li>
            <NavLink to="/change-password" className={({ isActive }) => (isActive ? 'active' : '')}>
              <FaKey className="icon" />
              修改密码
            </NavLink>
          </li>
          <li>
            <button className="logout-button" onClick={handleLogout}>
              <FaSignOutAlt className="icon" />
              注销
            </button>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
